<template>
  <admin-default v-slot:admin>
    <div class="application">
      <div class="container container-resize">
        <div class="row border-bottom pb-5">
          <div class="col-md-9"><h1 class="application__secondary--title">Calculator</h1></div>
          <div class="col-6 col-md-3">
            <h1 class="application__main--title">Welcome,</h1>
            <h1 class="application__tertiary--title">{{user.name}}</h1>
          </div>
        </div>
      </div>

      <div class="container container-resize ">
        <div class="row">
          <div class="col-md-12">
            <div class="calculators">
              <div class="calculators-authorize">
                <h2>Amortization Calculator</h2>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="label" class="form__label">Type of Loan</label>
                    <select class="form-control form__input"
                            id="label"
                            @change="ChangeUser($event)"
                            v-model="calculate.interest"
                    >
                      <option value="">Select loan type</option>
                      <option v-for="(value, index) in productCode"
                              :key="index" :value="value.daily_rate">{{value.display_name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="calculators-calculate">
                    <div class="row">
                      <div class="col-md-12">
                        <input-forms placeholder="amount"
                                     label="Loan Amount"
                                     name="amount"
                                     type="number"
                                     v-model.trim="calculate.amount"></input-forms>
                      </div>
                      <div class="col-md-12">
                        <input-forms placeholder="no of month"
                                     label="Terms monthly: "
                                     name="month"
                                     type="number"
                                     v-model.trim="calculate.month"></input-forms>
                      </div>
                      <div class="col-md-12">
                        <button type="button" class="btn button button-cta cta mr-1"
                                @click.prevent="CalculateAmount">Calculate</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="calculators-calculates">
                    <h4>Complete Amount to be paid</h4>
                    <h5>{{formatters(monthly)}}</h5>
                    <div class="row">
                      <div class="col-md-6">
                        <p>Total Principal Paid</p>
                      </div>
                      <div class="col-md-6">
                        <p v-if="calculate.amount">{{formatters(calculate.amount )}}</p>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-6">
                        <p>Total Interest Paid</p>
                      </div>
                      <div class="col-md-6">
                        <p>{{ formatters(interest) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </admin-default>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import InputForms from "../forms/InputForms";
import AdminDefault from "../navigate/AdminDefault";
export default {
name: "Calculators",
  components: {InputForms, AdminDefault},
  data () {
    return {
      calculate: {
        amount: null,
        month: null,
        interest: ''
      },
      interest: 0,
      monthly: 0
    }
  },
  methods: {
    ...mapActions({
      ProductCode: 'loan/ProductCode'
    }),
    CalculateAmount () {
      if (!this.calculate.type) {
        this.$toastr.w('Please select loan type')
        return false
      }

      if (this.calculate.amount < 1 || this.calculate.month < 1){
        this.$toastr.w('Enter amount and number of month')
        return false
      }

      if (this.calculate.type) {
        this.interest = ( parseInt(this.calculate.amount) *
            parseInt(this.calculate.month) * this.calculate.interest) / 100
        if (this.interest) {
          this.monthly = (( parseInt(this.calculate.amount) *
              parseInt(this.calculate.month) * this.calculate.interest) / 100) + parseInt(this.calculate.amount)
        }
      }
    },
    ChangeUser (value) {
      this.calculate.type = value.target.value
    },
    async LoadProductCode () {
      await this.ProductCode()
    }
  },
  computed:{
    ...mapGetters({
      user: 'auth/user',
      productCode: 'loan/productCode',
    })
  },
  created () {
    this.LoadProductCode()
  }
}
</script>

<style scoped>

</style>
