<template>
  <div class="form-pays">
    <div v-if="pays">
      <h1>Pay Outstanding Loans</h1>
      <h3>Total to be paid: {{ formatters((pays.loan_schedule.install_amount))}}</h3>
      <form ref="form" >
        <b-form-group
            label="Name"
            label-for="name"
            invalid-feedback="name"
            :state="nameState"
        >
          <b-form-input
              id="name"
              v-model="user.name"
              :state="nameState"
              required
              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
            label="User Email"
            label-for="email"
            invalid-feedback="email"
            :state="nameState"
        >
          <b-form-input
              id="amount"
              v-model="user.login"
              :state="nameState"
              required
              disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
            label="Total Amount"
            label-for="amount"
            invalid-feedback="amount"
            :state="nameState"
        >
          <b-form-input
              id="amount"
              :value="pays.loan_schedule.install_amount"
              :state="nameState"
              required
              disabled
          ></b-form-input>
        </b-form-group>
      </form>
      <paystack
          :amount="(pays.loan_schedule.install_amount) * 100"
          :email="user.login"
          :paystackkey=" paymentKey"
          :reference="`${pays.prelim_id}_${reference}`"
          :callback="CheckoutPaymentNow"
          :close="close"
          :metadata="{
            amount: pays.amount,
            appl_type: pays.appl_type,
            applicant_ids: pays.applicant_ids,
            approved_by: pays.approved_by,
            branch: pays.branch,
            capital: pays.capital,
            cl_acc_no: pays.cl_acc_no,
            company_id: pays.company_id,
            customer_acc_id: pays.customer_acc_id,
            financing_amt: pays.financing_amt,
            id: pays.id,
            install_count: pays.install_count,
            is_company_customer: pays.is_company_customer,
            loanbal: pays.loanbal,
            loaninterest: pays.loaninterest,
            mature_date: pays.mature_date,
            monthly_debt1: pays.monthly_debt1,
            monthly_debt2: pays.monthly_debt2,
            loan_schedule: pays.loan_schedule,
            monthlypayment: pays.monthlypayment,
            name: pays.name,
            no_of_employees: pays.no_of_employees,
            partner_id: pays.partner_id,
            prelim_app_id: pays.prelim_app_id,
            prelim_id: pays.prelim_id,
            requested_amt: pays.requested_amt,
            schedule_ids: pays.schedule_ids,
            sub_branch: pays.sub_branch}"
      >
        Pay {{ `${ formatters(pays.loan_schedule.install_amount) }` }}
      </paystack>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Paystack from 'vue-paystack';
export default {
  name: "PaymentCheckout",
  data () {
    return {
      payment: {},
      nameState: null,
      submittedNames: [],
      amount: this.pays ? this.pays.loan_schedule.install_amount : 0
    }
  },
  props: {
    paymentKey: {
      type: String,
      default: process.env.VUE_APP_PAYSTACK_SECRET_KEY
    }
  },
  components: {
    Paystack
  },
  methods: {
    ...mapActions({
      PayloadAfter: 'loan/PaymentCompleted'
    }),
    async CheckoutPaymentNow (value) {
      const formData = new FormData
      console.log(value)
      console.log(this.pays)
      formData.append('date_invoice', new Date().toISOString().slice(0, 10))
      formData.append('partner_id', this.user.partner_id[0])
      formData.append('placement_id', this.pays.id)
      formData.append('loandeductdetails_id', this.pays.loan_schedule.id)
      formData.append('payment_ref', value.reference)
      formData.append('phone', this.user.mobile)
      formData.append('cust_email', this.user.login)
      formData.append('actualtotal_paid', this.pays.loan_schedule.install_amount)
      formData.append('transaction_ref', value.trxref)
      formData.append('transaction_no', value.trans)
      formData.append('paymentstatus', value.status)

      const response = await this.PayloadAfter(formData)

      if (response.status) {
        this.$toastr.s(this.message)
        this.$root.$emit('bv::toggle::modal', 'my-modal', '#my-modal')
      }

    },
    close () {

    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      pays: 'loan/payment',
      errors: 'setup/errors',
      message: 'setup/message'
    }),
    reference() {
      let text = "";
      let possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },

  },

}
</script>

<style scoped>

</style>
