<template>
  <div class="row mt-5">
    <div class="col-md-12 mb-5" style="overflow: scroll">
      <div class="m-4 float-right" v-if="showButton">
        <button type="button" class="btn button button-cta cta mr-1" @click="PayAllDue">Pay All Due</button>
      </div>
      <b-table striped hover
               :items="schedule_loans" id="table"
               :fields="fields"
               :per-page="perPage"
               selectable
               :current-page="currentPage"
               class="size-table">
        <template #cell(index)="data">
          <div class="form-checksss cks">
            <label >
              {{data.index + 1 }}
            </label>
          </div>
        </template>
        <template #cell(loan_schedule.schddate)="data">
          {{new Date(data.item.loan_schedule.schddate).toDateString()}}
        </template>
        <template #cell(requested_amt)="data">
          {{ formatters(data.item.requested_amt)}}
        </template>
        <template #cell(monthlypayment)="data">
          {{ formatters(data.item.monthlypayment) }}
        </template>
        <template #cell(loan_schedule.schedule_principal)="data">
          {{ formatters(data.item.loan_schedule.schedule_principal)}}
        </template>
        <template #cell(loan_schedule.schedule_interest)="data">
          {{ formatters(data.item.loan_schedule.schedule_interest)}}
        </template>
        <template #cell(loan_schedule.install_amount)="data">
          {{ formatters(data.item.loan_schedule.install_amount)}}
        </template>

        <template #cell(amount)="data">
          <span v-if="!data.item.loan_schedule.paid_yn">
            {{ formatters(`-${data.item.loan_schedule.install_amount}`)}}
          </span>
        </template>

        <template #cell(loan_schedule.paid_yn)="data">
          <button v-if="data.item.loan_schedule.paid_yn" class="btn btn-paid mr-1">Paid</button>
          <button v-if="!data.item.loan_schedule.paid_yn"
                  @click.prevent="PayOneTask(data.item)"
                  class="btn btn-unpaid mr-1">Pay Now</button>
          <button class="btn btn-info" @click.prevent="SchedulerReport(data.item)"
                  v-if="$route.fullPath === '/schedule-report'">Schedule</button>
        </template>
      </b-table>
      <div class="rows">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <bootstrap-modals v-slot:modal>
      <payment-checkout></payment-checkout>
    </bootstrap-modals>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import BootstrapModals from "../../modal/bootstrapModals";
import PaymentCheckout from "./PaymentCheckout";
export default {
  name: "TablesPay",
  components: {PaymentCheckout, BootstrapModals},
  data() {
    return {
      perPage: 12,
      currentPage: 1,
      fields: [
        {
          key: 'index', label: 'S/N'
        },
        {
          key: 'loan_schedule.paid_yn',
          label: 'Status',
        },
        {
          key: 'loan_schedule.schddate',
          label: 'Schedule Date',
        },
        {
          key: 'requested_amt',
          label: 'Loan Amount',
        },
        {
          key: 'monthlypayment',
          label: 'Monthly Payment',
        },
        {
          key: 'loan_schedule.schedule_principal',
          label: 'Schedule Payment',
        },
        {
          key: 'loan_schedule.schedule_interest',
          label: 'Schedule Interest',
        },
        {
          key: 'loan_schedule.install_amount',
          label: 'Installment Amount',
        },
        {
          key: 'amount',
          label: 'Due Balance',
        },
      ],
      items: [],
      list: [],
      showButton: false
    }
  },
  watch: {
    list (value) {
      if (value.length) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    }
  },
  methods: {
    ...mapMutations({
      Payloan: 'loan/payment'
    }),
    PayAllDue () {
      this.$root.$emit('bv::toggle::modal', 'my-modal', '#my-modal')
    },
    ListedMethods (value) {
      // this.$root.$emit('bv::toggle::modal', 'my-modal', '#my-modal')
      if (this.list.length) {
        if (this.list.find(val => val.pay_reference === value.pay_reference)) {
          this.list.splice(this.list.indexOf(value), 1)
        } else {
          this.list.push(value)
        }
      }else {
        this.list.push(value)
      }
    },
    PayOneTask (value) {
      this.Payloan(value)
      this.$root.$emit('bv::toggle::modal', 'my-modal', '#my-modal')
    },
    SchedulerReport (value) {
      this.Payloan(value)
    }
  },
  computed: {
    ...mapGetters({
      payee: 'loan/payment',
      schedule_loans: 'loan/schedule_loans'
    }),

    scheduler () {
      return this.sortByUnpaidItems (this.schedule_loans)
    },

    rows() {
      return this.schedule_loans.length
    }
  }
}
</script>

<style scoped>

</style>
