<template>
    <admin-default v-slot:admin>
        <div class="application" >
            <div class="container container-resize">
                <div class="row border-bottom pb-5 pl-4">
                    <div class="col-md-9"><h1 class="application__secondary--title">Loan Details</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{user.name}}</h1>
                    </div>
                </div>
            </div>
            <!--NAV-->
            <div class="container container-resize">
                <tables-pay></tables-pay>
            </div>
        </div>
    </admin-default>
</template>

<script>
    import AdminDefault from "../navigate/AdminDefault";
    import TablesPay from "./pages/TablesPay";
    export default {
        name: "Loan",
        components: {TablesPay, AdminDefault}
    }
</script>

<style scoped>

</style>
