var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-pays"},[(_vm.pays)?_c('div',[_c('h1',[_vm._v("Pay Outstanding Loans")]),_c('h3',[_vm._v("Total to be paid: "+_vm._s(_vm.formatters((_vm.pays.loan_schedule.install_amount))))]),_c('form',{ref:"form"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","invalid-feedback":"name","state":_vm.nameState}},[_c('b-form-input',{attrs:{"id":"name","state":_vm.nameState,"required":"","disabled":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('b-form-group',{attrs:{"label":"User Email","label-for":"email","invalid-feedback":"email","state":_vm.nameState}},[_c('b-form-input',{attrs:{"id":"amount","state":_vm.nameState,"required":"","disabled":""},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", $$v)},expression:"user.login"}})],1),_c('b-form-group',{attrs:{"label":"Total Amount","label-for":"amount","invalid-feedback":"amount","state":_vm.nameState}},[_c('b-form-input',{attrs:{"id":"amount","value":_vm.pays.loan_schedule.install_amount,"state":_vm.nameState,"required":"","disabled":""}})],1)],1),_c('paystack',{attrs:{"amount":(_vm.pays.loan_schedule.install_amount) * 100,"email":_vm.user.login,"paystackkey":_vm.paymentKey,"reference":((_vm.pays.prelim_id) + "_" + _vm.reference),"callback":_vm.CheckoutPaymentNow,"close":_vm.close,"metadata":{
          amount: _vm.pays.amount,
          appl_type: _vm.pays.appl_type,
          applicant_ids: _vm.pays.applicant_ids,
          approved_by: _vm.pays.approved_by,
          branch: _vm.pays.branch,
          capital: _vm.pays.capital,
          cl_acc_no: _vm.pays.cl_acc_no,
          company_id: _vm.pays.company_id,
          customer_acc_id: _vm.pays.customer_acc_id,
          financing_amt: _vm.pays.financing_amt,
          id: _vm.pays.id,
          install_count: _vm.pays.install_count,
          is_company_customer: _vm.pays.is_company_customer,
          loanbal: _vm.pays.loanbal,
          loaninterest: _vm.pays.loaninterest,
          mature_date: _vm.pays.mature_date,
          monthly_debt1: _vm.pays.monthly_debt1,
          monthly_debt2: _vm.pays.monthly_debt2,
          loan_schedule: _vm.pays.loan_schedule,
          monthlypayment: _vm.pays.monthlypayment,
          name: _vm.pays.name,
          no_of_employees: _vm.pays.no_of_employees,
          partner_id: _vm.pays.partner_id,
          prelim_app_id: _vm.pays.prelim_app_id,
          prelim_id: _vm.pays.prelim_id,
          requested_amt: _vm.pays.requested_amt,
          schedule_ids: _vm.pays.schedule_ids,
          sub_branch: _vm.pays.sub_branch}}},[_vm._v(" Pay "+_vm._s(("" + (_vm.formatters(_vm.pays.loan_schedule.install_amount))))+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }