<template>
  <b-modal ref="my-modal" id="my-modal" :size="size ? size : 'md'" hide-footer hide-header>
    <slot name="modal"></slot>
  </b-modal>
</template>

<script>
export default {
  name: "bootstrapModals",
  props: {
    size: {
      type: String,
      required: false,
    }
  }
}
</script>

<style scoped>

</style>
